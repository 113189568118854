import { ICatalogueProduct } from 'src/modules/catalogue/models/catalogue.model';

export interface IMicrosoftSubscription {
    id: string;
    name: string;
    nickname: string;
    quantity: number;
    sku: string;
    promotionId: string;
    isTrial: boolean;
    autoRenewEnabled: boolean;
    status: SubscriptionStatus;
    billingCycle: MicrosoftBillingCycle;
    termDuration: MicrosoftTermDuration;
    commitmentEndDate: Date;
    creationDate: Date;
    effectiveStartDate: Date;
    parentSubscriptionId: string;
    refundStates: IRefundStates[];
    renewal?: IRenewal;
    refundableQuantity: IRefundableQuantity;
    subscriptionId: string;
    options?: IMicrosoftSubscriptionOptions;
    attributes?: SubscriptionAttributes;
    credentialsId?: string;
}

export enum SubscriptionAttributes {
    AllowManageQuantity = "AllowManageQuantity",
    AllowManageRenewal = "AllowManageRenewal",
    AllowManageUpdate = "AllowManageUpdate"
}
export interface IRefundStates {
    expires: Date;
    type: RefundStates;
}

export interface IRefundableQuantity {
    totalQuantity: number;
    details: IRefundableQuantityDetails;
}

export interface IRefundableQuantityDetails {
    quantity: number;
    allowedUntil: Date;
}

export interface IRenewal {
    product: IRenewalProduct;
    quantity: number;
    termEndDate: string | Date | null;
}

export interface IRenewalProduct {
    catalogueId: string | null;
    billingCycle: string;
    termDuration: string;
    promotionId: string | null;
}

export interface IProvisionMicrosoftSubscription {
    catalogueId: ICatalogueProduct['id'];
    quantity: number;
    autoRenew: boolean;
    sku: string; //property not required by provision api anymore, but used for setting up dummy subscription while license is being provisioned
    billingCycle: number; //property not required by provision api anymore, but used for setting up dummy subscription while license is being provisioned
    termCycle: number;//property not required by provision api anymore, but used for setting up dummy subscription while license is being provisioned
}

export interface IUpdateMicrosoftSubscription {
    nickname: string;
    quantity: number;
    autoRenewEnabled: boolean;
    status: SubscriptionStatus;
    renewal?: IRenewal;
}

export interface IMicrosoftSubscriptionOptions {
    subscriptionId: string;
    renewalOptions: IRenewalOptions;
    statusOptions: SubscriptionStatus;
    refundableQuantity: IRefundableQuantity;
    upgrades: IMicrosoftSubscriptionUpgrade[];
}

export interface IMicrosoftSubscriptionUpgrade {
    skuId: string;
    catalogItemId: string;
    title: string;
    description: string;
    terms: IMicrosoftSubscriptionUpgradeTerms[];
    eligibilities: IMicrosoftSubscriptionEligibilities[];
}

export interface IMicrosoftSubscriptionUpgradeTerms {
    termDuration: string;
    billingCycle: string;
}

export interface IMicrosoftSubscriptionEligibilities {
    isEligible: boolean;
    transitionType: TransitionType;
    errors: [{
        code: number;
        description: string;
    }];
}

export enum TransitionType {
    transition_only = "transition_only",
    transition_with_license_transfer = "transition_with_license_transfer"
}

export interface IUpgradeMicrosoftSubscriptionPayload {
    targetSku?: string;
    termDuration?: MicrosoftTermDuration;
    billingCycle?: MicrosoftBillingCycle;
    targetSubscriptionId?: string;
    quantity: number;
    transitionType: string;
}

export interface IRenewalOptions {
    supportedBillingCycles: MicrosoftBillingCycle;
    supportedTermDurations: MicrosoftTermDuration;
}

export enum MicrosoftBillingCycle {
    Unknown = 'Unknown',
    None = 'None',
    OneTime = 'OneTime',
    Monthly = 'Monthly',
    Annual = 'Annual',
    Biennial = 'Biennial',
    Triennial = 'Triennial'
}



export enum MicrosoftTermDuration {
    None = 'None', P1M = 'P1M', P1Y = 'P1Y', P3Y = 'P3Y'
}

export enum TermDurationsString {
    None = 'None', P1M = '1 month', P1Y = '1 year', P3Y = '3 years',
}


export enum TermDurationsNumber {
    'None' = 0, 'P1M' = 1, 'P1Y' = 12, 'P3Y' = 36
}

export enum BillingCyclesNumber {
    'Unknown' = 0,
    'None' = 0,
    'OneTime' = -1,
    'Monthly' = 1,
    'Annual' = 12,
    'Biennial' = 24,
    'Triennial' = 36
}

export enum RefundStates {
    None = 'None', Partial = 'Partial', Full = 'Full', Cancellation = 'Cancellation'
}

export enum SubscriptionStatus {
    Unknown = 'Unknown',
    None = 'None',
    Active = 'Active',
    Suspended = 'Suspended',
    Deleted = 'Deleted',
    Expired = 'Expired',
    Pending = 'Pending',
    Provisioning = 'Provisioning',
    Failed = 'Failed',
    Updating = 'Updating',
    Upgrading = 'Upgrading',
}

export enum StatusColor {
    Active = 'success-color',
    Suspended = 'danger-color',
    Expired = 'danger-color',
    Deleted = 'danger-color',
    Failed = 'danger-color',
    Pending = 'warning-color',
    Provisioning = 'lightblue-color',
    Updating = 'lightblue-color',
    Upgrading = 'lightblue-color',
    None = 'grey-color',
    Unknown = 'grey-color',

    Cancelled = 'danger-color',
    Ended = 'danger-color',

}
